<script setup lang="ts">
    import type { AddressDto } from '@containex/portal-backend-dto';
    import { useI18n } from 'vue-i18n';
    import { isStringEmpty } from '@containex/common-utils';

    defineProps<{
        address: AddressDto;
        includePhone?: boolean;
        includeCompanyName?: boolean;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div>
        <div v-if="includeCompanyName">{{ address.company }}</div>
        <div>{{ address.address1 }}</div>
        <div>{{ address.address2 }}</div>
        <div>{{ address.countryCode?.toUpperCase() }} - {{ address.postalCode }} {{ address.city }}</div>
        <div v-if="includePhone && !isStringEmpty(address.phone)">
            {{ t('ACCOUNT.TELEPHONE') }}: {{ address.phone }}
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as main;

    .phone {
        display: inline-block;
    }

    .pi-phone {
        font-size: main.$font-size-2;
        vertical-align: middle;
    }
</style>
